import React from 'react';
import './../../index.css';

function Support(){
    return(
        <div className="container-support">
        <p>Implemented by: Olga Ersov and Victor Belozerov</p>
            <p>If you have any questions please feel free contact us</p>
            <p>ersovolga7@gmail.com </p>
            <p>victor.teit@gmail.com</p>
        </div>
    )
}
export default Support;