import React from 'react';
import logoSmall from '../assets/logoClape.png';
import img404 from "../assets/CLAPE404.svg"
import {useTranslation} from "react-i18next";

function Page404(){
    const { t } = useTranslation();
    return(
        <div className="container-404">
            <div className="container-404-logo"><img src={logoSmall} alt={'logo-small'}/></div>
            <div className="container-404-img-message">
                <img className="img-404" src={img404} alt={'404'}/>
                <div className="container-404-message">
                    <h6>{t("page404.message")}</h6>
                </div>
            </div>
            <div className="container-404-btn"><button className="btn-contact"><a href="https://fr.clape.ca/fr-contact/">{t("page404.button")}</a></button></div>
            <div className="container-copyright"><a href="https://fr.clape.ca/" className="copyright">© 2023 Copyright  Clape Agency Inc.</a></div>
        </div>
    )
}

export default Page404;