import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import logoFr from '../../assets/CLAPE_FR_MainLogo_NOIR-1024x548.png';
import logoEn from '../../assets/CLAPE_ENG_MainLogo_BLACK-1024x541.png';
import {useNavigate} from 'react-router-dom';
import './../../index.css';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(()=> {
    document.title = "Login | Clape admin";
      }, []);
  const handleLogin = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);

      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          localStorage.setItem("is_authenticated", true);
          setIsAuthenticated(true);
          navigate('/management/main');

          Swal.fire({
            icon: "success",
            title: "Successfully logged in!",
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
    } catch (error) {
      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error.message,
            showConfirmButton: true,
          });
        },
      });
    }
  };

  return (
    <div className="container-login">
      <div className="logo-images">
        <img className="img-lang" src={logoFr} alt="logo_fr"/>
        <img className="img-lang" src={logoEn} alt="logo_en" />
      </div>
      <div className="login-form">
      <form onSubmit={handleLogin}>
        <h1 style={{fontSize: "1.5em"}}>Admin Login</h1>
        <input
            style={{ height: "40px", textAlign: "center", marginTop: "25px"  }}
          id="email"
          type="email"
          name="email"
          placeholder="admin@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
            style={{ height: "40px", textAlign: "center", marginTop: "25px"  }}
          id="password"
          type="password"
          name="password"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input className="button btn-login" type="submit" value="Login" />
      </form>
      </div>
    </div>
  );
};
export default Login;
