import React from 'react';
import './../../index.css'


function Footer({setIsAuthenticated}){
    return(
        <footer>
            <div>
                <p>© 2023 Copyright Clape Agency Inc.</p>
            </div>
        </footer>
    )
}
export default Footer;