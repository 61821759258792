import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function VisibilityComponent({ editedVisibility, setEditedVisibility }){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);
    return(
        <div className="container-edit-visibility">
            <h4>{t('questionCard.visibility')}</h4><input value={editedVisibility} onChange={(e) => setEditedVisibility(e.target.value)} />
        </div>
    )
}
export default VisibilityComponent;