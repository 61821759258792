import React, {useContext, useEffect} from 'react';
import imageId from "../../assets/image-qid.png";
import imageType from "../../assets/typeQuestions.png";
import imageYesNo from "../../assets/imageYesNo.png";
import newTemplate from "../../assets/newTemplate.png";
import imageCreateSurvey from "../../assets/createSurvey.png"
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';


function TutorialQuestion(){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(()=> {
        document.title = [t('pages.tutorial')];
    }, []);
    return (
        <div className="tutorial-container">
            <h1 className="header-tutorial">Tutorial and FAQ</h1>
            <h2>Edit or create new question for survey</h2>
            <div className="tutorial-card">
                <h3>1. Generating IDs for Questions</h3>
                <div className="tutorial-id-create">
                    <img className="img-id" src={imageId} alt={"image-example"}/>
                <p>When creating questions for our survey system,
                    each question requires a unique ID. This ID helps in organizing,
                    referencing, and managing questions efficiently. In our system,
                    <span className="attention-message">you cannot change the ID of an existing question; you must delete
                        the entire question and create a new one if a change is required.</span>
                    Hence, it's crucial to follow the ID generation guidelines.</p>
                <ul>
                    <li>The beginning of each question ID starts with a prefix that related the category of the survey. For example "qwd" - question web design</li>
                    <li>After selecting the appropriate prefix, you need to append a number to it. This number signifies the order of the question.
                        Use three digits for numbering. It helps in maintaining uniformity and accommodates up to 999 questions per category.
                        Start numbering from 001, then 002, and so on.</li>
                    <li>When assigning numbers to questions, it's essential to understand that numbers with different lengths are sorted as strings, not integers, which can lead to unintended ordering.</li>
                    <span className="attention-message"><li>Do not mix different numbering lengths like 01 and 001. It will only cause confusion and misordering.</li></span>
                    <li>Stick to a consistent length for all numbers. If you decide on a three-digit format, always use three digits (e.g., 001, 010, 100).</li>
                    <li> Always include leading zeros for numbers less than the maximum length you've decided on. So, for a three-digit format, 3 becomes 003, 20 becomes 020.</li>
                <li>Combine the prefix and the number to generate the full ID.
                    For example, the second question for merchandise would have the ID qmr002, while the tenth question would be qmr010.</li>
                </ul>
                <h4>Best practice: </h4>
                <ul>
                    <li>Always stick to the aforementioned format. It ensures easy referencing in the future.</li>
                    <li>Even if you have a small number of questions initially, using three digits prepares you for expansion.</li>
                </ul>
                </div>
                <h3>2. Select type</h3>
                <div className="tutorial-id-create">
                    <img className="img-id" src={imageType} alt={"image-example"}/>
                    <ul>
                        <h4><ol>Info</ol></h4>
                        <ul>
                            <li>To provide the user with specific information.</li>
                            <li>No interaction from the user's side as it's purely informational.</li>
                            <li>Represented as a panel with clear text.
                                A good use case might be to provide instructions or context before a series of questions.</li>
                        </ul>
                        <h4><ol>Yes/No (Custom Options):</ol></h4>
                        <ul>
                            <li>To get a specific choice from the user.</li>
                            <li> User chooses one option from a list. It's not limited to just
                                "Yes" or "No" but can include custom options.</li>
                            <li>At least 2 options required, but there's no upper limit.</li>
                        </ul>
                        <img src={imageYesNo}/>
                        <h4><ol>Text: </ol></h4>
                        <ul>
                            <li>To allow the user to provide a free-form answer.</li>
                            <li> User can type in their response.</li>
                            <li>Presented as a text area.</li>
                            <li> No limit on the number of characters, but consider adding a counter if
                                you decide to introduce a limit in the future.</li>
                        </ul>
                        <h4><ol>Color: </ol></h4>
                        <ul>
                            <li>To allow users to pick a color.</li>
                            <li>Users can choose a color using a color picker tool.</li>
                        </ul>
                        <h4><ol>PickPictures:</ol></h4>
                        <ul>
                            <li>To allow users to select an image from a set of options.</li>
                            <li> Users can view and select one image from the presented options.</li>
                            <li>Thumbnails of the images can be presented in a grid or list format. Ensure that the images are clear and of high quality.</li>
                            <li>A minimum of 2 and a maximum of 6 pictures can be presented for selection.</li>
                        </ul>
                    </ul>
                </div>
                <h3>3. Visible conditions</h3>
                <div className="tutorial-id-create">
                    <p>Visible conditions dictate the flow of your survey by
                        deciding which question will be shown next based on user's responses
                        to previous questions. These conditions are paramount because
                        they determine the user's journey through the survey and ensure
                        that they are only shown questions relevant to them.</p>
                    <p>Using visible conditions wisely will make your survey dynamic and more user-friendly,
                        ensuring participants are only shown relevant questions based on their previous answers.</p>
                    <span className="attention-message"><p>If you don't put any visible conditions in this field it means question will be show by default</p></span>
                    <h5 style={{textAlign: "center"}}>Operators 'or' / 'and'</h5>
                    <div className="block-operators">
                        <div>
                            <h2>OR</h2>
                            <ul>
                                <li> It's used when <span className="attention-message">at least one</span> of the conditions separated by the 'or' operator is true.</li>
                                <li>If you have a condition like {`{qwd006}`} = 'Yes' or {`{qwd015}`} = 'No', it means the next question will
                                    show if the response to question qwd006 is 'Yes' OR the response to question qwd015 is 'No'.</li>
                                <li>It broadens the criteria.</li>
                            </ul>
                        </div>
                        <div>
                            <h2>AND</h2>
                            <ul>
                                <li> It's used when <span className="attention-message">all conditions</span> separated by the 'and' operator have to be true.</li>
                                <li>If you have a condition like {`{qwd006}`} = 'Yes' and {`{qwd015}`} = 'No', it means the next question will
                                    show only if the response to question qwd006 is 'Yes' AND the response to question qwd015 is 'No'.</li>
                                <li>It narrows down the criteria.</li>
                            </ul>
                        </div>
                    </div>
                    <h3>Custom Responses in Yes/No Questions:</h3>
                    <p>When using custom options in a 'yes/no' type of question,
                        validation is crucial. The conditions you write in the
                        validation should match the options verbatim. If the option
                        is 'I need content creation', <span className="attention-message">your validation must be</span>
                         {`{number question}`} = 'I need content creation'.</p>
                    <h3>Language Considerations:</h3>
                    <p>As you're supporting multiple languages, ensure the conditions cater
                        to all supported languages. For instance, {`{qwd006}`} = 'Yes' or {`{qwd015}`} = 'No'
                        handles the English version, whereas {'{qwd006}'} = 'Oui' or {`{qwd015}`} = 'Non'
                        caters to the French version.</p>
                    <p>You can combine all logic for each language if it's more then
                        1 criteria in one block and separate 'or' for example:
                        <span className="attention-message">(</span>{`{qmr003}`} = 'Yes' or {`{qmr003}`} = 'No'<span className="attention-message">) or
                        (</span>{`{qmr003}`} = 'Oui' or {`{qmr003}`} = 'Non'<span className="attention-message">)</span></p>
                    <p className="attention-message">Be cautious with special characters. In Firebase, certain characters, like single
                        quotes ('), can be reserved. If your response contains such characters, you'll need
                        to escape them. For instance, the French word "J'ai" should be written as J\'ai in conditions.</p>
                    <h3>Condition: notempty</h3>
                    <p>The condition '{`{qwd014}`} notempty' is great for questions like 'color' or 'pickPictures'.
                        It means if the user has selected an option or filled in an answer, the next question becomes visible. </p>
                </div>
                <h3>4. Create new survey</h3>
                <div className="tutorial-id-create">
                <img className="img-id" src={imageCreateSurvey} alt={"image-example"}/>
                <h6>Field for Survey Name: </h6>
                    <ul>
                        <li>This is the title or name of your survey. It should be descriptive enough
                            to let users know what the survey is about.</li>
                        <li>Can be more then 1 word. For example: Web Design in both languages</li>
                        <li>The survey name should be written with first word starting
                            with a capital letter.</li>
                        <li>If you're conducting surveys about merchandise for different brands,
                            you might title them "Merch / Adidas" for Adidas-related items,
                            or "Merch / Volvo" for Volvo merchandise. This naming convention
                            helps prevent confusion and ensures each survey is easily identifiable.</li>
                    </ul>
                <h6>Field for Survey ID: </h6>
                 <ul>
                        <li>This is a unique identifier for your survey, which will
                            be used in URLs and database references.</li>
                        <li>The survey ID should be in lowercase.</li>
                        <li><span className="attention-message">It should be one word without spaces.</span></li>
                     <li>It should be related to your survey's name or topic,
                         making it easy to associate the ID with the survey's content.</li>
                     <li>Keep it short, but unique enough to differentiate from other surveys.</li>
                     <li>For the "Branding" a suitable ID might be "brand".</li>
                    </ul>
                </div>
                <h3>5. Create new survey using existing like template</h3>
                <div className="tutorial-id-create">
                    <img className="img-id" src={newTemplate} alt={"image-example"}/>
                        <ul>
                            <li>Input the new survey's name in both English and the
                                secondary language in the provided fields "Survey Name in
                                English" and "Nome de Sondage". Ensure that the names are descriptive
                                and reflect the content of your survey.</li>
                            <li>Assign a unique "New Survey ID" to your survey.
                                This ID must be distinct and not previously used to
                                ensure that your survey data is correctly identified and stored.</li>
                            <li> Choose an existing template from the 'Copy from' dropdown menu to use as a starting
                                point for your new survey. <span className="attention-message">This will import all
                                    content and logic from the selected template.</span></li>
                        </ul>
                    <h3 className="attention-message">Attention! Don't forget!</h3>
                    <ul>
                    <li>After the template is copied, go through the survey questions and
                        logic flow. Make any necessary adjustments to suit the specific
                        needs of your new survey. This may include updating question text,
                        answer options, and branching logic based on responses.</li>
                        <li>Before distributing your survey, perform a thorough review
                            to confirm that all questions are relevant, the flow of logic is
                            correct, and that the survey operates as intended. And try it in Test Mode</li>
                    </ul>
                    </div>
            </div>
        </div>
    )
}

export default TutorialQuestion;