import {useState, useEffect, useContext} from 'react';
import TableClients from '../Dashboard/TableClients';
import {db} from "../../config/firestore";
import './../../index.css';
import { collection, getDocs} from 'firebase/firestore';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import Swal from "sweetalert2";

const ListClients = () => {
  const [clients, setClients] = useState();
  const [isEditing] = useState(false);
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);



  const getClients = async () => {
    try{
    const querySnapshot = await getDocs(collection(db, "clients"));
    const clients = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
    setClients(clients)
  } catch (error) {
      Swal.fire({
        icon: "error",
        title: [t("swal.error")],
        text: [t("swal.errorFetchClients")],
        buttonsStyling: false,
        customClass: {
          confirmButton: 'button swal-button-ok',
        }
      });
    }
  };

  useEffect(() => {
    getClients()
  }, []);
 

  return (
    <div className="container-quotes">
      { !isEditing && (
        <>
          <TableClients
            clients={clients}
          />
        </>
      )}
    </div>
  );
};

export default ListClients;
