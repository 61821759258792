import React, {useContext, useEffect, useState} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {db} from "../../config/firestore";
import {collection, doc, getDocs, setDoc} from "firebase/firestore";
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import './../../index.css';


function SendToCustomer() {
    const {language} = useContext(LanguageContext);
    const {t} = useTranslation();
    const [selectedSurvey, setSelectedSurvey] = useState('all');
    const [surveys, setSurveys] = useState([]);
    const [generatedLink, setGeneratedLink] = useState('');

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(() => {
        document.title = [t('pages.send')];
    }, []);
    useEffect(() => {
        const fetchSurveys = async () => {
            const querySnapshot = await getDocs(collection(db, 'services'));
            const surveyOptions = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setSurveys(surveyOptions);
        };

        fetchSurveys();
    }, []);


    const handleSurveyChange = (e) => setSelectedSurvey(e.target.value);
    const handleSubmit = (e) => {
        e.preventDefault();
        handleSendSurvey();
    };



    async function handleSendSurvey() {

        if (selectedSurvey === 'all') {
            Swal.fire({
                title: t("swal.selectSurvey"),
                text: t("swal.selectMessage"),
                icon: 'warning',
                iconColor: 'red',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button swal-button',
                }
            });
            return;
        }

        const token = uuidv4();
        const surveyTokenRef = doc(collection(db, 'tokens'));


        try {
            Swal.fire({
                title: [t("swal.moment")],
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1000,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            await setDoc(surveyTokenRef, {
                surveyId: selectedSurvey,
                token: token,
                isCompleted: false
            });

            const surveyLink = `https://service.clape.ca/survey/?token=${token}`;
            setGeneratedLink(surveyLink);
        } catch (error) {
            console.error("Error saving token:", error);

        }
    }
    const copyToClipboard = async (link) => {
        try {
            await navigator.clipboard.writeText(link);
            Swal.fire({
                title: [t("swal.copy")],
                icon: 'success',
                timer: 1500,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button swal-button',
                }
            });
        } catch (err) {
            Swal.fire({
                title: "Failed",
                icon: 'error',
            });
        }
    };


    function onHandleClear() {
        setSelectedSurvey('')
        setGeneratedLink('')
    }

    return (
            <div className="survey-form-container">
                <h2>{t("main.sending")}</h2>
                <form className="form-send-email" onSubmit={handleSubmit}>
                    <div className="container-generate-link-small"><label className="label-send-page"
                                                                       htmlFor="select-survey">{t("spinners.selectPlease")}</label>
                        <select id="select-survey" value={selectedSurvey} onChange={handleSurveyChange}>
                            <option value="all">{t("spinners.filter")}</option>
                            {surveys.map((survey) => (
                                <option key={survey.id} value={survey.id}>{survey.name}</option>
                            ))}
                        </select>
                        <button className="button btn-edit" type="submit">{t("buttons.generate")}</button>
                    </div>
                </form>
                {generatedLink && (
                    <div className="generated-link-display">
                        <h2>{generatedLink}</h2>
                        <button className="button btn-update" onClick={() => copyToClipboard(generatedLink)}>{t("buttons.copy")}</button>
                        <button className="button btn-cancel" onClick={onHandleClear}>{t("buttons.clear")}</button>
                    </div>
                )}
            </div>
        )
    }

export default SendToCustomer;