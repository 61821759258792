import React, {useState, useEffect, useContext} from "react";
import Swal from "sweetalert2";
import {convertToDate} from "./TableQuotes";
import {collection, doc, deleteDoc, getDoc, getDocs} from "firebase/firestore";
import { db } from "../../config/firestore";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useNavigate, useParams} from "react-router-dom";
import './../../index.css'

const QuoteDetails = () => {
  const {language} = useContext(LanguageContext);
  const {t} = useTranslation();
  const {id} = useParams();
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [responsesWithQuest, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(()=> {
    document.title = t('pages.details');
  }, [t]);

  const exportToCsv = (data, filename = 'export.csv') => {
    let csvContent = 'Date, Client ID, Id question, Question, Answer\r\n';
    data.forEach(item => {
      const id = item.id_question;
      const question = language === 'fr' ? item.question?.questionLabelFR : item.question?.questionLabel;
      let answer = item.response;
      const date = convertToDate(quoteDetails.date);
      const clientId = quoteDetails.client?.email;
      if (item.response.charAt(0) === '#') {
        answer = `"${answer}"`;
      } else if (item.question?.questionType === "pickPictures") {
        answer = `"${answer}"`;
      } else {
        answer = `"${answer?.replace(/"/g, '""')}"`;
      }

      const row = `"${date}", "${clientId}", "${id}","${question}","${answer}"\r\n`;
      csvContent += row;
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };



  useEffect(() => {
    const fetchQuoteDetails = async () => {
      try{
      const quoteDocRef = doc(db, 'quotes', id);
      const quoteSnap = await getDoc(quoteDocRef);

      if (quoteSnap.exists()) {
        const quoteData = quoteSnap.data();
          const clientDocRef = doc(db, 'clients', quoteData.email);
          const clientSnap = await getDoc(clientDocRef);
          const serviceDocRef = doc(db, 'services', quoteData.id_survey);
          const serviceSnap = await getDoc(serviceDocRef);


          if (clientSnap.exists() && serviceSnap.exists()) {
            const clientData = clientSnap.data();
            const serviceData = serviceSnap.data();
            setQuoteDetails(prev => ({ ...quoteData, quoteData: id, client: clientData, service: serviceData }));
          } else {
            console.log('No such client document!');
          }

        if (quoteData.responses && quoteData.id_survey) {
          const questionsRef = collection(db, `services/${quoteData.id_survey}/questions`);
          const questionsSnapshot = await getDocs(questionsRef);
          const questions = questionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          const responses = quoteData.responses.map(response => {
            const question = questions.find(q => q.id === response.id_question);
            return { ...response, question: question || {} };
          });

          setQuestions(responses);
      } else {
        console.error('No quote found with the given ID:', id);
      }}
    }catch (error) {
      console.error("Error fetching quote details:", error);
    }
  };

    fetchQuoteDetails();
  }, [id, language]);


  const handleDelete = async () => {
    const result = await Swal.fire({
      icon: "warning",
      iconColor: 'red',
      title: [t("swal.sure")],
      text: [t("swal.revert")],
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'button swal-button',
        cancelButton: 'button swal-button'
      },
      confirmButtonText: [t("swal.confirm")],
      cancelButtonText: [t("swal.cancel")],
    });
    if (result.isConfirmed) {
      try {
        await deleteDoc(doc(db, "quotes", id));
        Swal.fire({
          icon: "success",
          title: [t("swal.deleted")],
          text: [t("swal.quoteDeleted")],
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1)

      } catch (error) {
        console.error("Error deleting document: ", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: [t("swal.wrong")],
          buttonsStyling: false,
          customClass: {
            confirmButton: 'button swal-button-ok',
          }
        });
      }
    }
  };
  if (!quoteDetails || responsesWithQuest.length === 0) {
    return <div>Loading quote details...</div>;
  }

  return (
    <div className="contain-table">
      <div className="container-details-client">
        <div className="details-client">
          <h1 className="header-details-client">{t('questionCard.company')}: {quoteDetails.client.companyName}</h1>
          <h1 className="header-details-client">{t('questionCard.email')}: {quoteDetails.client.email}</h1>
          <h1 className="header-details-client">{t('questionCard.date')}: {convertToDate(quoteDetails.date)}</h1>
          <h1 className="header-details-client">Service: {language === 'en' ? quoteDetails.service.name : quoteDetails.service.nameFR}</h1>

        </div>
        <div className="details-client-actions">
          <button onClick={() => exportToCsv(responsesWithQuest)} className="button btn-edit">CSV</button>
          <button onClick={handleDelete} className="button btn-delete">{t("buttons.delete")}</button>
        </div>
        </div>
      <table className="striped-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Question</th>
            <th>{t("questionCard.answers")}</th>
          </tr>
        </thead>
        <tbody>
        {responsesWithQuest.map((response, index) => (
              <tr key={index}>
                <td> {response.id_question}</td>
                <td>{language === 'fr' ? response.question?.questionLabelFR : response.question?.questionLabel}</td>
                {response.response.charAt(0) === "#" ? (
                    <div
                      style={{
                        backgroundColor: response.response,
                        width: "70px",
                        height: "30px",
                        color: "white",
                      }}
                    >
                      {response.response}
                    </div>
                    ) : (
                    response.question?.questionType === "pickPictures" ? (
                        <img src={response.response} alt="Response" className="imgResponsQuote" />
                    ) : (
                        response.response
                    )
                  )}
          </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuoteDetails;
