import React, {useContext, useEffect} from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css'

const Logout = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleLogout = () => {
    Swal.fire({
      icon: 'question',
      iconColor: 'green',
      title: [t("swal.logout")],
      text: [t("swal.logSure")],
      showCancelButton: true,
      confirmButtonText: [t("swal.yes")],
      cancelButtonText: [t("swal.cancel")],
      buttonsStyling: false,
      customClass: {
        confirmButton: 'button swal-button',
        cancelButton: 'button swal-button'
      }
    }).then(result => {
      if (result.value) {
        Swal.fire({
          timer: 1500,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            localStorage.setItem('is_authenticated', false);
            setIsAuthenticated(false);
            navigate('/management');
          },
        });
      }
    });
  };

  return (
    <button
      className="button btn-logout"
      onClick={handleLogout}>{t('buttons.logout')}</button>
  );
};

export default Logout;
