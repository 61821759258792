import React, {useContext, useEffect, useState} from "react";
import {collection, doc, getDocs, setDoc} from "firebase/firestore";
import {db} from "../../config/firestore";
import {Link, useNavigate} from "react-router-dom";
import Swal from 'sweetalert2';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

const CreateSurveyFromTemplate = ()=> {
    const [surveyNameEn, setSurveyNameEn] = useState("");
    const [surveyNameFr, setSurveyNameFr] = useState("");
    const [surveyId, setSurveyId] = useState("");
    const [surveyIds, setSurveyIds] = useState([]);
    const [surveyOptions, setSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState("");
    const navigate = useNavigate();
    const {language} = useContext(LanguageContext);
    const {t} = useTranslation();


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const fetchSurveys = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'services'));
            const surveyOptions = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setSurveys(surveyOptions);
            const fetchedIds = querySnapshot.docs.map(doc => doc.id);
            setSurveyIds(fetchedIds);
        } catch {
            Swal.fire({
                icon: "error",
                title: [t("swal.error")],
                text: [t("swal.errorFetchSurveys")],
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button swal-button-ok',
                }
            });
        }
    };

    useEffect(() => {
        fetchSurveys();
    }, []);
console.log(surveyOptions)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!surveyNameEn || !surveyNameFr || !surveyId || selectedSurvey === "all") {
            return Swal.fire({
                icon: "error",
                title: [t("swal.error")],
                text: [t("swal.require")],
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button swal-button',
                }
            })
        } else if (surveyIds.includes(surveyId)) {
             return Swal.fire({
                 title: [t("swal.error")],
                 html: [t(`swal.existS`)],
                 icon: "error",
                 showConfirmButton: true,
                 buttonsStyling: false,
                 customClass: {
                     confirmButton: 'button swal-button',
                 }
             })
        }

        else {
            try {
                await handleCopySurvey(selectedSurvey, surveyId, surveyNameEn, surveyNameFr);
                await Swal.fire({
                    icon: "success",
                    title: [t("swal.success")],
                    text: [t("swal.newSurvey")],
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'button swal-button',
                    }
                });
                navigate(`/management/surveys/${surveyId}`);
            } catch (error) {
                console.error("Error during survey creation:", error);
                await Swal.fire({
                    icon: "error",
                    title: [t("swal.error")],
                    text: [t("swal.trying")],
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'button swal-button',
                    }
                });
            }
        }
    };
        const handleSurveyChange = (e) => setSelectedSurvey(e.target.value);

        function onHandleCancel() {
            setSurveyNameEn("");
            setSurveyNameFr("");
            setSurveyId("");
            setSelectedSurvey("");
            Swal.fire({
                icon: 'error',
                title: [t("swal.cancelling")],
                showConfirmButton: false,
                timer: 1500
            })
            navigate(-1);
        }
    const handleCopySurvey = async(sourceCollection, destinationCollection, surveyNameEn, surveyNameFr) => {
        const copyData = async () => {

            const sourceQuerySnapshot = await getDocs(
                collection(db, `services/${sourceCollection}/questions`)
            );
            const dataToCopy = sourceQuerySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))

            for (const data of dataToCopy) {
                const docRef = doc(db, `services/${destinationCollection}/questions`, data.id);
                await setDoc(docRef, data);
                await setDoc(doc(db,`services`,`${destinationCollection}`),{name: surveyNameEn, nameFR: surveyNameFr},{merge:true});
            }
            console.log("Data copied successfully.");
        };
        copyData();
    }

        return (
            <div className="container-template-create-new">
                <h2>{t("questionCard.newSurvey")}</h2>
                <p>{t('questionCard.please')} <Link to="/management/tutorial" target="_blank"><span className="attention-message">{t('questionCard.read')}</span></Link> {t('questionCard.before')}</p>
                <form className="form-send-email" onSubmit={handleSubmit}>
                    <div className="">
                        <input
                            placeholder="SurveyToClient Name in English:"
                            className="input-template"
                            type="text"
                            id="surveyNameEn"
                            value={surveyNameEn}
                            onChange={e => setSurveyNameEn(e.target.value)}
                        />
                    </div>
                    <div className="">
                        <input
                            placeholder="Nome de Sondage:"
                            className="input-template"
                            type="text"
                            id="surveyNameFr"
                            value={surveyNameFr}
                            onChange={e => setSurveyNameFr(e.target.value)}
                        />
                    </div>
                    <div >
                        <input
                            placeholder="New/Nouveau ID:"
                            className="input-template"
                            type="text"
                            id="surveyId"
                            value={surveyId}
                            onChange={e => setSurveyId(e.target.value)}
                        />
                    </div>
                    <div className="container-send-email-small input-template"><label className="label-send-page">{t("questionCard.copy")}
                    </label>
                        <select  id="select-survey" value={selectedSurvey} onChange={handleSurveyChange}>
                            <option value="all">{t("spinners.filter")}</option>
                            {surveyOptions.map((survey) => (
                                <option key={survey.id} value={survey.id}>{survey.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="btns-template">
                        <button className="button btn-edit" type="submit">{t("buttons.create2")}</button>
                        <button className="button btn-cancel" type="button" onClick={onHandleCancel}>{t("buttons.cancel")}</button>
                    </div>
                </form>
            </div>
        )
    }

export default CreateSurveyFromTemplate;


