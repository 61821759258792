import React, { useState } from 'react';
import logo from '../assets/logoClape.png';
import './ModuleCSS/ClientDetails.css';
import {useTranslation} from "react-i18next";



function ClientDetails({  onComplete }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const { t } = useTranslation();

    function handleSubmit(e){
        e.preventDefault();

        const newClient = {
            firstName,
            lastName,
            email,
            phone,
            companyName,
            companyAddress
        };
        onComplete(newClient);
    }


    return (
        <form onSubmit={handleSubmit} className="form">
            <div className="client-form">
                <div className="logo-box">
                    <img src={logo} alt="Clape logo" />
                </div>
                <h2>{t('client_details.intro')}</h2>
                <div className="input-row">
                    <input type="text" id="customer_information" placeholder={t('client_details.first_name')} required value={firstName} onChange={e => setFirstName(e.target.value)} />
                    <input type="text" id="customer_information" placeholder={t('client_details.last_name')} required value={lastName} onChange={e => setLastName(e.target.value)} />
                </div>
                <div className="input-row">
                    <input type="tel" id="customer_information" placeholder={t('client_details.phone')} value={phone} onChange={e => setPhone(e.target.value)} />
                    <input type="email" id="customer_information" placeholder={t('client_details.email')} required value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="input-row-one">
                    <input type="text" id="customer_information" placeholder={t('client_details.company_name')} required value={companyName} onChange={e => setCompanyName(e.target.value)} />
                </div>
                <div className="input-row-one">
                    <input type="text" id="customer_information" placeholder={t('client_details.address')} value={companyAddress} required onChange={e => setCompanyAddress(e.target.value)} />
                </div>
                <button className="button-details" type="submit">{t('client_details.start')}</button>
            </div>
        </form>
    );
}

export default ClientDetails;