import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function UploadImageComponent({images, handleImageChange, fileInputRef, uploadProgress, uploadedImageLinks, deleteImage}) {
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    return (
        <div className="image-upload-preview-container">
            {images.length < 6 && (
                <>
                    <label className="button select-img" htmlFor="imageInput">{t('buttons.selectImg')}</label>
                    <input
                        id="imageInput"
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        hidden={uploadProgress > -1 || uploadedImageLinks}
                    />
                    {!uploadedImageLinks && uploadProgress > -1 && (
                        <div>
                            <label htmlFor="file">Upload Progress:</label>
                            <progress id="file" value={uploadProgress} max="100">
                                {uploadProgress}%
                            </progress>
                            <span>{uploadProgress}%</span>
                        </div>
                    )}
                </>
            )}
            {images.length > 0 && (
                <div className="image-preview">
                    {images.length === 6 && (
                        <div><h4 className="label-maximum-images">You have reached the maximum uploaded images.</h4></div>
                    )}
                    <div className="image-preview-container">
                        {images.map((image, index) => (
                            <div key={index} className="image-preview-item">
                                <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} className="image" />
                                <button className="btn-delete-on-image" onClick={() => deleteImage(index)}>X</button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
export default UploadImageComponent;