import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function FilterQuestions({setFilterById, setFilterByType, filterByType, filterById}){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();



    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const handleFilterChange = (e, filterType) => {
        if (filterType === "id") {
            setFilterById(e.target.value);
        } else if (filterType === "type") {
            setFilterByType(e.target.value);
        }
    }
    return (
        <div className="filters">
            <h4>{t('surveyDetails.filter')}</h4>
            <select value={filterByType} onChange={(e) => handleFilterChange(e, "type")}>
                <option value="">{t('spinners.filter')}</option>
                <option value="text">text</option>
                <option value="yes/no">yes/no</option>
                <option value="info">info</option>
                <option value="color">color</option>
                <option value="pickPictures">pickPictures</option>
            </select>
            <h4>{t('surveyDetails.show')}</h4>
            <input className="input-filter-id" type="text" value={filterById} onChange={(e) => handleFilterChange(e, "id")}/>
        </div>
    )
}

export default FilterQuestions;