import './i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import {LanguageProvider} from "./components/App/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <LanguageProvider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
    </LanguageProvider>
);
