import React from 'react';
import './ModuleCSS/Introduction.css';
import { useTranslation } from 'react-i18next';

function Introduction(props) {
    const { t } = useTranslation();

    return (
        <div className="intro-container">
            <div className="logo-box">
                <img src={props.logo} alt="Clape logo" />
            </div>
            <h1 className="h1-introduction">{t('introduction.welcome')}</h1>
            <button className="button-introduction" onClick={()=> props.onNext()}>{t('introduction.next')}</button>
        </div>
    );
}
export default Introduction;