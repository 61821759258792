import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const SwalLoading = () => {
    useEffect(() => {
        Swal.fire({
            title: 'Loading...',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
        });

        return () => {
            Swal.close();
        };
    }, []);

    return <div></div>;
};

export default SwalLoading;
