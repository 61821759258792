import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function YesNoEditComponent({setEditedChoices, setEditedChoicesFR, editedType, editedChoices, question, editedChoicesFR}){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const handleChoiceEdit = (e, index, lang) => {
        if (lang === "ENG") {
            let newChoices = [...editedChoices];
            newChoices[index] = e.target.value;
            setEditedChoices(newChoices);
        } else if (lang === "FR") {
            let newChoicesFR = [...editedChoicesFR];
            newChoicesFR[index] = e.target.value;
            setEditedChoicesFR(newChoicesFR);
        }
    };
    const addChoice = (lang) => {
        if (lang === "ENG") {
            setEditedChoices([...editedChoices, ""]);
        } else if (lang === "FR") {
            setEditedChoicesFR([...editedChoicesFR, ""]);
        }
    };
    const deleteChoice = (index, lang) => {
        if (lang === "ENG") {
            setEditedChoices(editedChoices.filter((_, idx) => idx !== index));
        } else if (lang === "FR") {
            setEditedChoicesFR(editedChoicesFR.filter((_, idx) => idx !== index));
        }
    };

    return (
        <div>
            {(editedType === "yes/no" || question.questionType === "yes/no") && (
                <>
                    <div className="edit-choices-arrays">
                        <div className="choices">
                            <h5>{t('questionCard.answers')} (ENG): </h5>
                            <button className="btn-add-icon" onClick={() => addChoice("ENG")}>➕</button>
                            <ul>
                                {editedChoices.map((choice, index) => (
                                    <li key={index}>
                                        <input className="input-choice" value={choice} onChange={(e) => handleChoiceEdit(e, index, "ENG")} />
                                        <button className="btn-delete-icon" onClick={() => deleteChoice(index, "ENG")}>➖</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="choices">
                            <h5>{t('questionCard.answers')} (FR): </h5>
                            <button className="btn-add-icon" onClick={() => addChoice("FR")}>➕</button>
                            <ul>
                                {editedChoicesFR.map((choice, index) => (
                                    <li key={index}>
                                        <input className="input-choice" value={choice} onChange={(e) => handleChoiceEdit(e, index, "FR")} />
                                        <button className="btn-delete-icon" onClick={() => deleteChoice(index, "FR")}>➖</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </div>

    )
}

export default YesNoEditComponent;