import React, {Suspense, useEffect, useState} from "react";
import { functions } from '../config/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {useTranslation} from "react-i18next";
import Language from "./Language";
import Introduction from "./Introduction";
import logo from "../assets/logoClape.png";
import ClientDetails from "./ClientDetails";
import {useLocation, useNavigate} from "react-router-dom";
import SwalLoading from "./SwalLoading";
import 'firebase/compat/functions';
import { httpsCallable } from 'firebase/functions';
import SurveyToClient from "./SurveyToClient";
import './ModuleCSS/SurveyModule.css'

function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

function ClientSurvey(){
    const [clientDetails, setClientDetails] = useState(null);
    const [validatedSurveyId, setValidatedSurveyId] = useState(null);
    const { i18n } = useTranslation();
    const [currentStep, setCurrentStep] = useState('lang');
    const queryParams = useQueryParams(); //
    const token = queryParams.get('token');
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'Sondage | Survey';
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        const auth = getAuth();

        if (token) {
            const validateToken = httpsCallable(functions, 'validateToken');
            validateToken({ token }).then((result) => {
                if (isSubscribed) {
                    if (result.data.valid) {
                        setValidatedSurveyId(result.data.surveyId);
                        signInWithCustomToken(auth, result.data.customToken)
                            .then(() => {
                                setCurrentStep('lang');
                            })
                            .catch((authError) => {
                                console.error('Authentication failed:', authError);
                                navigate('/survey/404');
                            });
                    } else {
                        navigate('/survey/404');
                    }
                }
            }).catch((e) => {
                if (isSubscribed) {
                    console.error('Error validating token:', e);
                    navigate('/survey/404');
                }
            });
        } else {
            navigate('/survey/404');
        }
        return () => {
            isSubscribed = false;
        };
    }, [navigate, token]);

    const onCompleteClientDetails = (newClientDetails) => {
        setClientDetails(newClientDetails);
        setCurrentStep('survey');
    };


    const handleNextClick = (lang) => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
        if (currentStep === 'lang') {
            setCurrentStep('intro');
        } else if (currentStep === 'intro') {
            setCurrentStep('clientDetails');
        } else if (currentStep === 'clientDetails') {
            setCurrentStep('survey');
        }
    };

    const handleSurveyCompletion = async (responses) => {
        if (!clientDetails) {
            return;
        }
        const processSurveyResponse = httpsCallable(functions, 'processSurvey');
        processSurveyResponse({ token, clientDetails, responses })
            .then((result) => {
                if (result.data.status === 'success') {

                } else {

                }
            })
            .catch((error) => {
                console.error('Error submitting survey:', error);
            });
    };


    return (
        <Suspense  fallback={<SwalLoading />}>
            <div className="App">
                {currentStep === 'lang' && <Language onNext={handleNextClick} />}
                {currentStep === 'intro' && <Introduction logo={logo} onNext={handleNextClick} />}
                {currentStep === 'clientDetails' && <ClientDetails onComplete={onCompleteClientDetails} onNext={handleNextClick} />}
                {currentStep === 'survey'  && validatedSurveyId && <SurveyToClient lang={i18n.language}
                                                                                    surveyId={validatedSurveyId}
                                                                                    onComplete={handleSurveyCompletion} onNext={handleNextClick}/>}
            </div>
        </Suspense>
    );

}

export default ClientSurvey;



