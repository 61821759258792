import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function SelectorTypeQuestion({editedType, handleTypeChange, question}){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    return(
        <div className="container-edit-type">
            <h4>{t('questionCard.type')}  </h4>
            <select value={editedType} onChange={handleTypeChange}>
                <option value={question.questionType}>{question.questionType}</option>
                {question.questionType !== "text" && <option value="text">text</option>}
                {question.questionType !== "yes/no" && <option value="yes/no">yes/no</option>}
                {question.questionType !== "info" && <option value="info">info</option>}
                {question.questionType !== "color" && <option value="color">color</option>}
                {question.questionType !== "pickPictures" && <option value="pickPictures">pickPictures</option>}
            </select>
        </div>
    )
}

export default SelectorTypeQuestion;