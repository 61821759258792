import React, {useContext, useEffect} from 'react';
import {doc, updateDoc, getDoc} from "firebase/firestore";
import Swal from "sweetalert2";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../config/firestore";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function SaveEditedQuestion (props) {
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();



    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);
    const {
        db,
        id,
        editingQuestion,
        editedLabelFR,
        editedLabel,
        editedType,
        editedVisibility,
        editedChoices,
        editedChoicesFR,
        questions,
        setQuestions,
        setEditingQuestion,
        editedImageChoices
    } = props;
    const saveQuestion = async () => {
        await Swal.fire({
            title: [t("swal.savingQ")],
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 5000,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const questionRef = doc(db, 'services', id, 'questions', editingQuestion);
            const originalQuestionSnapshot = await getDoc(questionRef);
            const originalQuestionData = originalQuestionSnapshot.data();

            let updates = {
                questionLabelFR: editedLabelFR,
                questionLabel: editedLabel,
                questionType: editedType,
                visibleIf: editedVisibility
            };
            if (originalQuestionData.questionType !== editedType) {
                if (editedType === 'text') {
                    updates.choices = [];
                    updates.choicesFR = [];
                    updates.imageChoices = [];
                } else if (editedType === 'pickPictures') {
                    let uploadedLinks = [];
                    const newImages = editedImageChoices.filter(image => !image.alreadyUploaded);
                    for (const image of newImages) {
                        const storageRef = ref(storage, `images/${id}/${image.name}`);
                        const snapshot = await uploadBytes(storageRef, image.raw);
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        uploadedLinks.push(downloadURL);
                    }
                    uploadedLinks = [...editedImageChoices.filter(image => image.alreadyUploaded).map(img => img.imageLink), ...uploadedLinks];

                    let imageData = uploadedLinks.map((link, index) => ({
                        imageLink: link,
                        value: link,
                    }));

                    updates.imageChoices = imageData;
                }

            } else {

                updates.choices = editedChoices;
                updates.choicesFR = editedChoicesFR;

            }

            await updateDoc(questionRef, updates);

            const updatedQuestions = questions.map(q => q.id === editingQuestion ? {
                ...q,
                ...updates
            } : q);

            setQuestions(updatedQuestions);
            setEditingQuestion(null);

            await Swal.fire({
                icon: 'success',
                title: [t("swal.saved")],
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            console.error("Error in saveQuestion:", error);

        }
    };

    return (
            <button className="button btn-update" onClick={saveQuestion}>{t('buttons.update')}</button>
        );

}

export default SaveEditedQuestion;