import React, {useState, useEffect, useContext} from "react";
import Swal from "sweetalert2";
import './../../index.css'
import TableQuotes from "./TableQuotes";
import QuoteDetails from "./QuoteDetails";
import {db} from "../../config/firestore";

import {
    collection,
    getDocs,
    getDoc,
    doc,
    orderBy,
    query
} from "firebase/firestore";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {Outlet} from "react-router-dom";
import QuoteFilter from "./QuoteFilter";

const ListQuotes = () => {
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [filterByType, setFilterByType] = useState('');
  const [filterByNameCompany, setFilterByNameCompany ] = useState('');
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const getQuotes = async () => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "quotes"), orderBy("date", "desc")));
            const rawQuotes = querySnapshot.docs.map((quoteDoc) => ({
                id: quoteDoc.id,
                ...quoteDoc.data(),
            }));

            const quotesWithClients = await Promise.all(
                rawQuotes.map(async (quote) => {
                    const clientDoc = doc(db, "clients", quote.email);
                    const clientData = (await getDoc(clientDoc)).data();
                    return {...quote, client: clientData};
                })
            );

            setQuotes(quotesWithClients);
            console.log(quotes)
        } catch (error) {
            console.error("Error fetching quotes:", error);
            Swal.fire({
                icon: "error",
                title: [t("swal.error")],
                text: [t("swal.errorFetchQuote")],
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button swal-button-ok',
                }
            });
        }
    };

    useEffect(() => {
        getQuotes();
    }, []);

    const filteredQuotes = quotes
        .filter(q => !filterByType || q.id_survey === filterByType)
        .filter(q => {
            const companyNameLower = q.client?.companyName?.toLowerCase() || "";
            return !filterByNameCompany || companyNameLower.includes(filterByNameCompany.toLowerCase());
        });

    const handleQuoteClick = (quote) => {
        setSelectedQuote(quote);
    };
    return (
        <div className="container-quotes">
            <div style={{ marginTop: '30px', marginBottom: '18px' }}>
                <h1 className="headerWithQuotes">{t('main.list')}</h1>
            </div>
            <QuoteFilter setFilterByType={setFilterByType}
                         filterByType={filterByType}
                         setFilterByNameCompany={setFilterByNameCompany}
                         filterByNameCompany={filterByNameCompany}/>
            <TableQuotes quotes={filteredQuotes}
                         onQuoteClick={handleQuoteClick}/>

            {selectedQuote && (
                <QuoteDetails
                    selectedQuote={selectedQuote}
                    setQuotes={setQuotes}
                    getQuotes={getQuotes}
                />
            )}
            <Outlet/>
        </div>
    );
};

export default ListQuotes;
