import React, { useState, useEffect } from 'react';
import Routing from './components/Navigation/Routing';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  useEffect(() => {
    const auth = localStorage.getItem('is_authenticated')
    setIsAuthenticated(auth === 'true');
  }, []);

  return (
        <Routing isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
  );
};

export default App;
