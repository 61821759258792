import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {db} from "../../config/firestore";
import './ListSurveys.module.css';
import {collection, getDocs, doc, setDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css'


function ListSurveys() {
    const [surveys, setSurveys] = useState([]);
    const [surveyIds, setSurveyIds] = useState([]);
    const {language} = useContext(LanguageContext);
    const {t} = useTranslation();
    useEffect(() => {
        document.title = [t('pages.surveys')];
    }, []);


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const getSurveys = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "services"));
            const surveys = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
            setSurveys(surveys)
            const surveyIds = querySnapshot.docs.map(doc => (doc.id))
            setSurveyIds(surveyIds)
        } catch (error){
            Swal.fire({
                icon: "error",
                title: [t("swal.error")],
                text: [t("swal.errorFetchSurveys")],
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button swal-button-ok',
                }
            });
        }
    };



    useEffect(() => {
        getSurveys()
    }, []);


    async function handleCreateNewSurvey() {
        const {value: formValues} = await Swal.fire({
            title: [t("swal.createSurvey")],
            footer: '<a href="/management/tutorial" target="_blank">How to fill these fields?</a>',
            html:
                '<input id="swal-input1" class="swal2-input" placeholder="SurveyToClient ENG">' +
                '<input id="swal-input2" class="swal2-input" placeholder="Sondage FR">' +
                '<input id="swal-input3" class="swal2-input" placeholder="SurveyToClient/Sondage ID">',
            showCancelButton: true,
            confirmButtonText: [t("swal.create")],
            showLoaderOnConfirm: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'button swal-button',
                cancelButton: 'button swal-button'
            },
            cancelButtonText: [t("swal.cancel")],
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value.trim(),
                    document.getElementById('swal-input2').value.trim(),
                    document.getElementById('swal-input3').value.trim()
                ];
            }
        });

        if (formValues) {
            const [surveyName, surveyNameFR, surveyId] = formValues;
            if (surveyName && surveyNameFR && surveyId) {
                const docRef = doc(db, "services", surveyId);

                if (surveyIds.includes(surveyId)) {
                    Swal.fire({title: [t("swal.error")], html: [t(`swal.existS`)], icon: "error"})
                } else {
                    try {
                        await setDoc(docRef, {
                            name: surveyName,
                            nameFR: surveyNameFR
                        });
                        Swal.fire(
                            {
                                title:[t("swal.saved")],
                                html:[t("swal.savedS")],
                                icon: 'success',
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: 'button swal-button',
                                }
                            }).then(() => {
                            window.location.reload();
                        });
                    } catch (error) {
                        Swal.fire({
                            title: t("swal.error"),
                            html: t("swal.problemCreate"),
                            icon: `error`
                        })
                    }
                }
            } else {
                Swal.fire([t("swal.error")], 'You need to fill in both fields.', 'error');
            }
        }
    }

    return <div className="surveys-container">
        {surveys.map(survey => <div className="survey-card" key={survey.id}>
            <Link to={`/management/surveys/${survey.id}`}>
                <h2>{language === 'fr' ? survey.nameFR : survey.name}</h2>
            </Link>
        </div>)}
        <div className="survey-card">
            <button className="button btn-new-survey"
                    onClick={handleCreateNewSurvey}>{t('buttons.create')}</button>
        </div>
        <div className="survey-card">
       
            <Link to={{
                pathname: "/management/surveys/create-from-template",
                state: surveys
            }}>
                <button className="button btn-new-survey">{t("buttons.createTemplate")}
                </button>
            </Link>
        </div>
    </div>;
}

export default ListSurveys;