import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import "survey-core/survey.i18n";
import 'animate.css';
import {db} from "../../config/firestore";
import {Survey} from "survey-react-ui";
import { Model } from "survey-core";
import {getDocs, collection} from "firebase/firestore";
import './../../index.css';

function TestSurvey () {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');
    const [surveyJSON, setSurveyJSON] = useState(null);
    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        if (surveyJSON) {
            const newSurvey = new Model(surveyJSON);
            newSurvey.onValueChanged.add((sender, options) => {
                sender.runConditions();
            });
            setSurvey(newSurvey);
        }
    }, [surveyJSON]);

    useEffect(() => {
        const fetchQuestions = async () => {
            const snapshot = await getDocs(collection(db, 'services', id, 'questions'));
            const questionsData = snapshot.docs.map(doc => {
                const data = doc.data();
                let questionStructure;

                let labelField = lang === 'fr' ? 'questionLabelFR' : 'questionLabel';
                let choicesField = lang === 'fr' ? 'choicesFR' : 'choices';

                switch(data.questionType) {
                    case 'text':
                        questionStructure = {
                            type: "comment",
                            name: doc.id,
                            title: data[labelField],
                            isRequired: data.isRequired || false,
                            visibleIf: '',
                            choices: data.choices,
                            clearIfInvisible: "onHiddenContainer"
                        };
                        break;

                    case 'pickPictures':
                        questionStructure = {
                            type: "imagepicker",
                            name: doc.id,
                            title: data[labelField],
                            isRequired: data.isRequired || false,
                            choices: data.imageChoices,
                            multiSelect: false,
                            visibleIf: '',
                            clearIfInvisible: "onHiddenContainer"
                        };
                        break;
                    case 'color':
                        questionStructure = {
                            type: "text",
                            name: doc.id,
                            title: data[labelField],
                            isRequired: data.isRequired || false,
                            inputType: "color",
                            visibleIf: '',
                            clearIfInvisible: "onHiddenContainer"
                        };
                        break;

                    case 'info':
                        questionStructure = {
                            type: "html",
                            html: data[labelField],
                            name: doc.id,
                            isRequired: data.isRequired || false,
                            visible: '',
                            clearIfInvisible: "onHiddenContainer"

                        };
                        break;

                    case 'yes/no':
                        questionStructure = {
                            type: "radiogroup",
                            name: doc.id,
                            title: data[labelField],
                            isRequired: data.isRequired || false,
                            choices: data[choicesField],
                            visibleIf: '',
                            clearIfInvisible: "onHiddenContainer"
                        };
                        break;
                    default:
                        questionStructure = {};
                }

                if (data.visibleIf && data.visibleIf.trim() !== "") {
                    questionStructure.visibleIf = data.visibleIf;
                }

                return questionStructure;
            });



            const generatedSurveyJSON = {
                textUpdateMode: "onTyping",
                pages: [
                    {
                        name: "singlePage",
                        elements: questionsData.map(question => ({
                            type: "panel",
                            name: question.name + "Panel",
                            elements: [question],
                        }))
                    }],
            };
            setSurveyJSON(generatedSurveyJSON);
        };
        fetchQuestions();
    }, [id, lang]);


    const onSurveyComplete = (survey, options) => {
        const responses = survey.data;
    };
    
    return survey ?
        <div>
            <Survey
                model={survey}
                onComplete={onSurveyComplete}
            />
        </div>
        : <p>Loading...</p>;
}

export default TestSurvey;