import React  from 'react';
import {useLanguage} from "../App/LanguageContext";
import './../../index.css';

function ToggleLanguage() {
    const { language, toggleLanguage } = useLanguage();

    return(
        <div className="toggle">
            <div className="toggle-button-cover">
                <div className="button-cover">
                    <div className="button-t b2" id="button-10">
                        <input type="checkbox" className="checkbox" checked={language === 'fr'}
                               onChange={toggleLanguage}/>
                        <div className="knobs">
                            <span>{language.toUpperCase()}</span>
                        </div>
                        <div className="layer"></div>
                    </div>
                </div>
        </div>
        </div>

    )
}

export default ToggleLanguage;