import Navigation from "./Navigation";
import {Route, Routes, useLocation} from "react-router-dom";
import ListClients from "../pages/ClientsPage";
import SurveysPage from "../pages/SurveysPage";
import SurveyDetails from "../pages/SurveyDetails";
import ListSurveys from "../Dashboard/ListSurveys";
import Login from "../Login";
import Logout from "../Logout";
import Dashboard from "../Dashboard/Dashboard";
import AddNewQuestion from "../Question/AddNewQuestion";
import TutorialQuestion from "../Question/TutorialQuestion";
import QuoteDetails from "../Dashboard/QuoteDetails";
import TestSurvey from "../pages/TestSurvey";
import ListQuotes from "../Dashboard/ListQuotes";
import SendToCustomer from "../pages/SendToCustomer"
import Footer from "./Footer";
import Support from "../pages/Support";
import CreateSurveyFromTemplate from "../pages/CreateSurveyFromTemplate";
import ClientSurvey from "../../survey/ClientSurvey";
import Page404 from "../../survey/Page404";
import '../../index.css'
function Routing({ setIsAuthenticated, isAuthenticated }) {
    const location = useLocation();
    const isManagementSection = location.pathname.startsWith('/management');

    return (
        <div>
          {isAuthenticated && isManagementSection && <Navigation setIsAuthenticated={setIsAuthenticated} /> }
          <Routes>
              <Route path="/survey/" element={<ClientSurvey />} />
              <Route path="/survey/404" element={<Page404 />} />
            {isAuthenticated ? (
                <>
            <Route path="/management/main" element={<Dashboard />} />
            <Route path="/management/clients" element={ <ListClients />} />
            <Route path="/management/quotes" element={<ListQuotes /> }/>
            <Route path="/management/quotes/details/:id" element={<QuoteDetails />}/>
            <Route path="/management/surveys" element={ <SurveysPage />}>
              <Route index element={<ListSurveys />} />
              <Route path=":id" element={<SurveyDetails />} />
              <Route path=":id/add-new-question" element={<AddNewQuestion />} />
              <Route path=":id/test-mode" element={<TestSurvey />} />
              <Route path="create-from-template" element={<CreateSurveyFromTemplate  />} />
            </Route>
            <Route path="/management/tutorial" element={<TutorialQuestion />} />
            <Route path="/management/send-survey-to-client" element={<SendToCustomer />} />
            <Route path="/management/support" element={<Support />} />
                </>
              ) : (
                  <>
              <Route path="/management" element={<Login setIsAuthenticated={setIsAuthenticated}/>} />
              <Route path="/management/logout" element={<Logout setIsAuthenticated={setIsAuthenticated} />} />
                </>
              )}
          </Routes>
            {isManagementSection && <Footer />}
        </div>
    );
  }
  
  export default Routing;