import React, {useContext, useEffect, useState} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {db} from "../../config/firestore";
import {collection, getDocs} from "firebase/firestore";
import './../../index.css'

function FilterQuotes({setFilterByType, filterByType, setFilterByNameCompany, filterByNameCompany}){
    const { language } = useContext(LanguageContext);
    const [surveyTypes, setSurveyTypes] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchSurveyTypes = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'services'));
                const types = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name,
                    nameFR: doc.data().nameFR,
                }));
                setSurveyTypes(types);
            } catch (error) {
                console.error("Error fetching survey types:", error);
            }
        };

        fetchSurveyTypes();
    }, [language]);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const handleFilterChange = (e, filterType) => {
        if (filterType === "type"){
            setFilterByType(e.target.value);
        } else if (filterType === "company") {
            setFilterByNameCompany(e.target.value);
        }
    }
    return (
        <div className="filters-quote">
            <div className="filters-quote-one">
            <h4>{t('surveyDetails.filterQuote')}</h4>
            <select value={filterByType} onChange={(e) => handleFilterChange(e, "type")}>
                <option value="">{t('spinners.filter')}</option>
                {surveyTypes.map(type => (
                    <option key={type.id} value={type.id}>
                        {language === 'fr' ? type.nameFR : type.name}
                    </option>
                ))}
            </select>
            </div>
            <div className="filters-quote-one">
            <h4>{t('surveyDetails.company')}</h4>
            <input className="input-filters-quote" type="text" value={filterByNameCompany} onChange={(e) => handleFilterChange(e, "company")}/>
                </div>
        </div>
    )
}

export default FilterQuotes;