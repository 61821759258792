import React, {useEffect, useState, useRef, useContext} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {db, storage} from "../../config/firestore";
import { doc, setDoc, getDoc } from 'firebase/firestore';
import Swal from "sweetalert2";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import YesNoChoiceComponent from "./YesNoChoiceComponent";
import UploadImageComponent from "./UploadImageComponent";
import VisibilityComponent from "./VisibilityComponent";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';


function AddNewQuestion(){
    const { id } = useParams();
    const navigate = useNavigate();
    const [serviceDetails, setServiceDetails] = useState(null);
    const [editQuestionId, setEditQuestionId] = useState('')
    const [editedLabel, setEditedLabel] = useState('');
    const [editedLabelFR, setEditedLabelFR] = useState('');
    const [editedType, setEditedType] = useState('');
    const [editedVisibility, setEditedVisibility] = useState('');
    const [editedChoices, setEditedChoices] = useState([]);
    const [editedChoicesFR, setEditedChoicesFR] = useState([]);
    const [images, setImages] = useState([]);
    const [uploadedImageLinks] = useState([]);
    const fileInputRef = useRef();
    const [uploadProgress] = useState(-1);
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();



    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);


    const fetchService = async () => {
        const querySnapshot = await getDoc(doc(db,"services", id));
        if(querySnapshot.exists()){
            setServiceDetails({id: querySnapshot.id, ...querySnapshot.data()});
        }
    }

    const handleBack = async() => {
        Swal.fire({
            icon: 'error',
            title: [t("swal.cancel")],
            showConfirmButton: false,
            timer: 1500
        })
        navigate(-1);
    }

    useEffect(() => {
        fetchService();
    }, [id]);

    const saveNewQuestion = async () => {
        Swal.fire({
            title: [t("swal.saving")],
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 5000,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const uploadedLinks = [];
        for (const image of images) {
            const storageRef = ref(storage, `images/${image.name}`);
            const snapshot = await uploadBytes(storageRef, image);
            const downloadURL = await getDownloadURL(snapshot.ref);
            uploadedLinks.push(downloadURL);
        }

        try {
            const questionRef = doc(db, 'services', id, 'questions', editQuestionId);
            const existingQuestionSnapshot = await getDoc(questionRef);

            if (existingQuestionSnapshot.exists()) {
                Swal.update({
                    icon: 'error',
                    title: 'Oops...',
                    html: [t("swal.existQ")],
                });
                return;
            }

            if (!editedType) {
                Swal.update({
                    icon: 'error',
                    title: 'Oops...',
                    html: [t("swal.selectType")],
                });
                return;
            }

            const questionData = {
                isRequired: true,
                questionLabelFR: editedLabelFR,
                questionLabel: editedLabel,
                questionType: editedType,
                visibleIf: editedVisibility,
                choices: editedChoices,
                choicesFR: editedChoicesFR,
                imageChoices: images
            };

            if (uploadedLinks.length > 0) {
                questionData.imageChoices = uploadedLinks.map((link, index) => ({
                    imageLink: link,
                    value: link,
                }));
            }

            await setDoc(questionRef, questionData);
            Swal.update({
                icon: 'success',
                title: [t("swal.saveWork")],
                html: ''
            });
            setTimeout(() => Swal.close(), 1500);

            navigate(-1);
        } catch (error) {

            Swal.update({
                icon: 'error',
                title: [t("swal.wrong")],
                html: error.message,
            });
            setTimeout(() => Swal.close(), 3000);
        }
    };

    const handleChoiceEdit = (e, index, lang) => {
        if (lang === "ENG") {
            const updatedChoices = [...editedChoices];
            updatedChoices[index] = e.target.value;
            setEditedChoices(updatedChoices);
        } else {
            const updatedChoicesFR = [...editedChoicesFR];
            updatedChoicesFR[index] = e.target.value;
            setEditedChoicesFR(updatedChoicesFR);
        }
    };
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImages(prev => [...prev, e.target.files[0]]);
        }
    };


    const deleteChoice = (index, lang) => {
        if (lang === "ENG") {
            const updatedChoices = [...editedChoices];
            updatedChoices.splice(index, 1);
            setEditedChoices(updatedChoices);
        } else {
            const updatedChoicesFR = [...editedChoicesFR];
            updatedChoicesFR.splice(index, 1);
            setEditedChoicesFR(updatedChoicesFR);
        }
    }
    function deleteImage(index) {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    }
    const addChoice = (lang) => {
        if (lang === "ENG") {
            setEditedChoices(prev => [...prev, ""]);
        } else {
            setEditedChoicesFR(prev => [...prev, ""]);
        }
    }

    return(
        <div className="questions-container">
            <h2>{t('questionCard.addQuestion')}</h2>
            {serviceDetails && ( <h1 className="title-question-container">{language === 'fr' ? serviceDetails.nameFR : serviceDetails.name} {t('questionCard.survey')}</h1>)}
            <h2>{t('questionCard.please')} <Link to="/management/tutorial" target="_blank"><span className="attention-message">{t('questionCard.read')}</span></Link> {t('questionCard.before')}</h2>
            <div className="question-card">
             <div className="line-edit-id">
                 <h4>{t('questionCard.id')} </h4><textarea className="input-edit-id" value={editQuestionId} onChange={(e) => setEditQuestionId(e.target.value)}/>
             </div>
                <h4>Question ENGLISH: </h4><textarea  value={editedLabel} onChange={(e) => setEditedLabel(e.target.value)} placeholder="Enter the English question text"/>
                    <h4>Question FRANÇAISE: </h4><textarea value={editedLabelFR} onChange={(e) => setEditedLabelFR(e.target.value)} placeholder="Saisir le texte de la question en français"/>
            <div className="container-edit-type-create">
                <div className="selector-type"><h4>{t('questionCard.type')} </h4>
                <select value={editedType} onChange={(e) => setEditedType(e.target.value)}>
                    <option value="" disabled>{t('spinners.selectPlease')}</option>
                    {["text", "yes/no", "info", "color", "pickPictures"].map(type => (
                        <option key={type} value={type}>{type}</option>
                    ))}
                </select>
                </div>
                {(editedType === "yes/no") && <YesNoChoiceComponent
                    editedChoices={editedChoices}
                    handleChoiceEdit={handleChoiceEdit}
                    addChoice={addChoice}
                    deleteChoice={deleteChoice}
                    editedChoicesFR={editedChoicesFR}
                />}
                {(editedType === "pickPictures") && <UploadImageComponent
                        images={images}
                        handleImageChange={handleImageChange}
                        fileInputRef={fileInputRef}
                        uploadProgress={uploadProgress}
                        uploadedImageLinks={uploadedImageLinks}
                         deleteImage={deleteImage}
                        />}
            </div>
                <VisibilityComponent
                    editedVisibility={editedVisibility}
                    setEditedVisibility={setEditedVisibility}
                    />
                <div className="buttons-edit">
                    <button className="button btn-update" onClick={saveNewQuestion}>{t('buttons.save')}</button>
                    <button className="button btn-cancel" onClick={handleBack}>{t('buttons.cancel')}</button>
                </div>
            </div>
        </div>
    )
}

export default AddNewQuestion;