import React, {useState, useEffect, useRef, useContext} from 'react';
import {Link, useParams,  useNavigate} from 'react-router-dom';
import Swal from "sweetalert2";
import {db} from "../../config/firestore";
import { collection, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import VisibilityComponent from "../Question/VisibilityComponent";
import RenderDefaultQuestion from "../Question/RenderDefaultQuestion";
import YesNoEditComponent from "../Question/YesNoEditComponent";
import SelectorTypeQuestion from "../Question/SelectorTypeQuestion";
import SaveEditedQuestion from "../Question/SaveEditedQuestion";
import DeleteQuestion from "../Question/DeleteQuestion";
import FilterQuestions from "../Question/FilterQuestions";
import EditImagesComponent from "../Question/EditImagesComponent";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';


const SurveyDetails = () => {
    const [questions, setQuestions] = useState([]);
    const [serviceDetails, setServiceDetails] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [editedLabel, setEditedLabel] = useState('');
    const [editedLabelFR, setEditedLabelFR] = useState('');
    const [editedType, setEditedType] = useState('');
    const [editedVisibility, setEditedVisibility] = useState('');
    const [setQuestionToDelete] = useState(null);
    const [editedChoices, setEditedChoices] = useState([]);
    const [editedChoicesFR, setEditedChoicesFR] = useState([]);
    const [editedImageChoices, setEditedImageChoices] = useState([]);
    const [filterById, setFilterById] = useState("");
    const [filterByType, setFilterByType] = useState("");
    const [uploadedImageLinks] = useState([]);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef();
    const [uploadProgress, setUploadProgress] = useState(-1);
    const navigate = useNavigate();
    const {id} = useParams();
    const [languageTest, setLanguageTest] = useState('');
    const {language} = useContext(LanguageContext);
    const {t} = useTranslation();
    useEffect(()=> {
        document.title = [t('pages.detailsSurvey')];
    }, []);


    const fetchServiceAndQuestions = async () => {
        const querySnapshot = await getDoc(doc(db, "services", id));
        if (querySnapshot.exists()) {
            setServiceDetails({id: querySnapshot.id, ...querySnapshot.data()});
        }
        const snapshot = await getDocs(collection(db, "services", id, "questions"));
        const fetchedQuestions = snapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        });
        setQuestions(fetchedQuestions);
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);


        useEffect(() => {
            fetchServiceAndQuestions();
        }, [id]);


        const startEditing = (question) => {
            setEditingQuestion(question.id);
            setEditedLabel(question.questionLabel);
            setEditedLabelFR(question.questionLabelFR);
            setEditedType(question.questionType);
            setEditedVisibility(question.visibleIf);
            setEditedChoices(question.choices);
            setEditedChoicesFR(question.choicesFR);
            const imageChoicesWithFlag = question.imageChoices.map(image => ({
                ...image,
                alreadyUploaded: true
            }));
            setEditedImageChoices(imageChoicesWithFlag);
        }
        const cancelEditing = () => {
            setEditingQuestion(null);
            setEditedLabel('');
            setEditedLabelFR('');
            setEditedType('');
            setEditedVisibility('');
            setEditedChoices(['']);
            setEditedChoicesFR(['']);
            setEditedImageChoices([null]);
            Swal.fire({
                icon: 'error',
                title: [t("swal.editCancel")],
                showConfirmButton: false,
                timer: 1500
            })
        };
        const handleLanguageSelect = (event) => {
            setLanguageTest(event.target.value);
        };
        const handleTestSurveyClick = () => {
            if (languageTest) {
                navigate(`/management/surveys/${id}/test-mode?lang=${languageTest}`);
            } else {
                Swal.fire({
                    title: [t("swal.testSurvey")],
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'button swal-button-ok',
                    }
                })
            }
        };

        const handleTypeChange = (e) => {
            const newValue = e.target.value;
            setEditedType(newValue);

            switch (newValue) {
                case "yes/no":
                    if (!editedChoices || editedChoices.length === 0) {
                        setEditedChoices([""]);
                        setEditedChoicesFR([""]);
                    }
                    setEditedImageChoices([]);
                    break;
                case "pickPictures":
                    if (editedImageChoices && editedImageChoices[0] && editedImageChoices[0].imageLink) {
                        setEditedImageChoices([null]);
                    }
                    setEditedChoices([]);
                    setEditedChoicesFR([]);
                    break;
                case "info":
                case "text":
                case "color":
                    setEditedChoices([]);
                    setEditedChoicesFR([]);
                    setEditedImageChoices([]);
                    break;
                default:
                    break;
            }
        };

        const deleteWholeSurvey = async () => {
            Swal.fire({
                title: [t("swal.sure")],
                text: [t("swal.deleteSurvey")],
                icon: 'warning',
                iconColor: 'red',
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'button swal-button',
                  cancelButton: 'button swal-button'
               },
                confirmButtonText: [t("swal.confirm")],
                cancelButtonText: [t("swal.cancel")],
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setUploading(true);
                    try {
                        const questionsSnapshot = await getDocs(collection(db, "services", id, "questions"));
                        const questionsDeletionPromises = questionsSnapshot.docs.map(doc => deleteDoc(doc.ref));
                        await Promise.all(questionsDeletionPromises);

                        await deleteDoc(doc(db, "services", id));
                        Swal.fire({
                            title:[t("swal.deleted")],
                            html: [t("swal.surveyDeleted")],
                            icon: 'success',
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'button swal-button',
                            }
                        }
                        );
                        navigate(`/management/surveys`);
                    } catch (error) {
                        console.error("Error deleting survey:", error);
                        Swal.fire(
                            'Error!',
                            'There was an error deleting the survey.',
                            'error'
                        );
                    } finally {
                        setUploading(false);
                    }
                }
            });
        };


        return (
            <div className="questions-container">
                <div className="title-container">
                    {serviceDetails && <h1 className="title-question-container">{language === 'fr' ? serviceDetails.nameFR : serviceDetails.name}</h1>}</div>
                <div className="selector-lang-test">
                    <h4>{t("surveyDetails.testMode")}</h4>
                    <select value={languageTest} onChange={handleLanguageSelect}>
                        <option value="" disabled>{t('spinners.select')}</option>
                        {<option value="en">English</option>}
                        {<option value="fr">Français</option>}
                    </select>
                    <button className="button btn-test" onClick={handleTestSurveyClick}>
                        {t("surveyDetails.test")}</button>
                </div>
                <div className="control-survey-header">
                    <button className="button btn-add-question">
                        <Link to={`/management/surveys/${id}/add-new-question`}>
                           {t('buttons.createNewQuestion')}</Link>
                    </button>
                    <button className="button btn-delete-survey" onClick={deleteWholeSurvey}>{t("surveyDetails.delete")}</button>
                </div>

                <FilterQuestions filterById={filterById}
                                 filterByType={filterByType}
                                 setFilterById={setFilterById}
                                 setFilterByType={setFilterByType}/>
                {questions
                    .filter(q => (filterByType ? q.questionType === filterByType : true) && (filterById ? q.id.includes(filterById) : true))
                    .map(question => (
                        <div className="question-card" key={question.id}>
                            {editingQuestion === question.id ? (
                                <div className="question-card-edit">
                                    <h6>({t('questionCard.hint')} <Link to="/management/tutorial" target="_blank"><span
                                        className="attention-message">{t('questionCard.readLink')}</span></Link>)</h6>
                                    <h4>{t('questionCard.id')} {question.id}</h4>
                                    <h4>Question (ENG): </h4><textarea value={editedLabel}
                                                                       onChange={(e) => setEditedLabel(e.target.value)}/>
                                    <h4>Question (FR): </h4><textarea value={editedLabelFR}
                                                                      onChange={(e) => setEditedLabelFR(e.target.value)}/>
                                    <SelectorTypeQuestion editedType={editedType}
                                                          question={question}
                                                          handleTypeChange={handleTypeChange}
                                    />
                                    <YesNoEditComponent question={question}
                                                        editedChoices={editedChoices}
                                                        editedChoicesFR={editedChoicesFR}
                                                        editedType={editedType}
                                                        setEditedChoices={setEditedChoices}
                                                        setEditedChoicesFR={setEditedChoicesFR}/>
                                    <EditImagesComponent setUploading={setUploading}
                                                         setEditedImageChoices={setEditedImageChoices}
                                                         editedImageChoices={editedImageChoices}
                                                         fileInputRef={fileInputRef}
                                                         uploadProgress={uploadProgress}
                                                         uploadedImageLinks={uploadedImageLinks}
                                                         question={question}
                                                         editedType={editedType}/>
                                    <VisibilityComponent editedVisibility={editedVisibility}
                                                         setEditedVisibility={setEditedVisibility}/>
                                    <div className="buttons-edit">
                                        <SaveEditedQuestion
                                            db={db}
                                            id={id}
                                            editingQuestion={editingQuestion}
                                            editedLabelFR={editedLabelFR}
                                            editedLabel={editedLabel}
                                            editedType={editedType}
                                            editedVisibility={editedVisibility}
                                            editedChoices={editedChoices}
                                            editedChoicesFR={editedChoicesFR}
                                            questions={questions}
                                            setQuestions={setQuestions}
                                            setEditingQuestion={setEditingQuestion}
                                            editedImageChoices={editedImageChoices}/>
                                        <button className="button btn-cancel"
                                                onClick={cancelEditing}>{t('buttons.cancel')}</button>
                                        <DeleteQuestion
                                            id={id}
                                            questions={questions}
                                            setQuestions={setQuestions}
                                            setQuestionToDelete={setQuestionToDelete}
                                            question={question}/>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <RenderDefaultQuestion question={question} startEditing={startEditing}/>
                                </>
                            )}
                        </div>
                    ))}
                <button className="button btn-add-question">
                    <Link to={`/management/surveys/${id}/add-new-question`}>
                        {t('buttons.createNewQuestion')}</Link>
                </button>
            </div>
        );
    }

    export default SurveyDetails;