import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../config/firestore";
import {Link} from "react-router-dom";
import './../../index.css'

const TableQuotes = ({ quotes, onQuoteClick }) => {
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(()=> {
        document.title = [t('pages.quotes')];
    }, []);

    const [surveys, setSurveys] = useState({});
    const loadSurveyNames = async (quoteList, currentLanguage) => {
        const surveyNames = {};
        const surveyIds = [...new Set(quoteList.map(quote => quote.id_survey))];

        for (const surveyId of surveyIds) {
            const surveyDoc = doc(db, 'services', surveyId);
            const surveyData = (await getDoc(surveyDoc)).data();
            surveyNames[surveyId] = (currentLanguage === 'fr' ? surveyData.nameFR : surveyData.name);
        }
        setSurveys(surveyNames);
    };

    useEffect(() => {
        if (quotes && quotes.length > 0) {
            loadSurveyNames(quotes, language);
        }
    }, [quotes, language]);

    return (
        <div className="contain-table">
            <table className="striped-table">
                <thead>
                <tr>
                    <th>No</th>
                    <th>Id</th>
                    <th>Date</th>
                    <th>{t("questionCard.company")}</th>
                    <th>{t("questionCard.email")}</th>
                    <th>Service</th>

                </tr>
                </thead>
                <tbody>
                {quotes ? (
                    quotes.map((quote, index) => {
                    const companyName = quote.client ? quote.client.companyName : 'No Company Name';
                    const email = quote.email ? quote.email : 'No Email';
                    const surveyName = surveys[quote.id_survey] ? surveys[quote.id_survey] : 'No Service Name';
                    return (
                        <tr key={quote.id}  onClick={() => onQuoteClick(quote)}>
                            <td>{(index += 1)}</td>
                            <td>{quote.id}</td>
                            <td>{convertToDate(quote.date)}</td>
                            <td>{companyName}</td>
                            <td>{email}</td>
                            <td>{surveyName}</td>
                            <td className="text-right">
                               <Link to={`/management/quotes/details/${quote.id}`}>
                                   <button className="button btn-edit">{t("buttons.details")}</button></Link>
                            </td>
                            <td className="text-left">
                            </td>
                        </tr>
                    ); })
                ) : (
                    <tr>
                        <td colSpan={7}>Loading...</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default TableQuotes;

export function convertToDate(Timestamp) {
    if (!Timestamp || !Timestamp.seconds || !Timestamp.nanoseconds) {
        return '';
    }
    const ts = (Timestamp.seconds + Timestamp.nanoseconds * 10 ** -9) * 1000;
    const date = new Date(ts);
    return date.toLocaleDateString();
}
