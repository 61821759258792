import React, {useContext, useEffect} from "react";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css'
const TableClients = ({ clients, handleDelete }) => {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  useEffect(()=> {
    document.title = [t('pages.clients')];
  }, []);

  function exportToCsvClients() {
    const headers = [
      "No",
      t("questionCard.company"),
      t("questionCard.email"),
      t("questionCard.address"),
      t("questionCard.contact"),
      t("questionCard.phone"),
    ].join(',');

    const csvRows = clients.map((client, index) => [
      index + 1,
      client.companyName,
      client.email,
      `"${client.companyAddress}"`,
      `${client.firstName} ${client.lastName}`,
      client.phone,
    ].join(','));


    const csvData = [headers, ...csvRows].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'clients.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
        <div className="container-list-client" style={{ marginTop: '30px', marginBottom: '18px' }}>
          <h1 className="headerWithQuotes">{t('main.clients')}</h1>
          <button onClick={() => exportToCsvClients()} className="button btn-edit">CSV</button>
    <div className="contain-table">
      <table className="striped-table">
        <thead>
          <tr>
            <th>No</th>
            <th>{t("questionCard.company")}</th>
            <th>{t("questionCard.email")}</th>
            <th>{t("questionCard.address")}</th>
            <th>{t("questionCard.contact")}</th>
            <th>{t("questionCard.phone")}</th>
          </tr>
        </thead>
        <tbody>
          {clients ? (
            clients.map((client, index) => (
              <tr key={client.id}>
                <td>{(index += 1)}</td>
                <td>{client.companyName}</td>
                <td>{client.email}</td>
                <td>{client.companyAddress}</td>
                <td>{client.firstName} {client.lastName}</td>
                <td>{client.phone}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No Clients</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
        </div>

  );
};

export default TableClients;
