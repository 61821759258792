import React, {useContext, useEffect} from 'react';
import logoFr from "../../assets/CLAPE_FR_MainLogo_NOIR-1024x548.png";
import logoEn from "../../assets/CLAPE_ENG_MainLogo_BLACK-1024x541.png";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import {LanguageContext} from "../App/LanguageContext";
import './../../index.css'


function Dashboard(){
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(()=> {
        document.title = [t('pages.main')];
    }, []);

    return(
        <div className="container-login">
            <h1 className="main-header">{t('main.welcome')}</h1>
            <div className="logo-images">
                <img className="img-lang" src={logoFr} alt="logo_fr"/>
                <img className="img-lang" src={logoEn} alt="logo_en" />
            </div>
            <div className="container-links">
                <h2><Link to="/management/tutorial">{t('main.tutorial')}</Link></h2>
                <h2><Link to="/management/send-survey-to-client">{t('main.sending')}</Link></h2>
                <h3><Link to="/management/support">Support</Link></h3>
            </div>
        </div>
    )
}
export default Dashboard;