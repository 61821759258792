import React, {useContext, useEffect} from 'react';
import {deleteDoc, doc} from "firebase/firestore";
import Swal from "sweetalert2";
import {db} from "../../config/firestore";
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';


function DeleteQuestion (props){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();



    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);
    const {
        id,
        questions,
        setQuestions,
        question
    } = props;
    const deleteQuestion = async (questionToDelete) => {
        Swal.fire({
            title: [t("swal.sure")],
            text: [t("swal.revert")],
            icon: 'warning',
            iconColor: 'red',
            showCancelButton: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'button swal-button',
                cancelButton: 'button swal-button'
            },
            confirmButtonText: [t("swal.confirm")],
            cancelButtonText: [t("swal.cancel")],
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const questionRef = doc(db, 'services', id, 'questions', questionToDelete);
                    await deleteDoc(questionRef);
                    const updatedQuestions = questions.filter(q => q.id !== questionToDelete);
                    setQuestions(updatedQuestions);
                    Swal.fire({
                        icon: "success",
                        title: [t("swal.deleted")],
                        text: [t("swal.questionDeleted")],
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'button swal-button'}
                        }
                    );
                } catch (error) {
                    Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: [t("swal.wrong")]}
                    );
                }
            }
        });
    };
    return (
        <button className="button btn-delete" onClick={() => {
            deleteQuestion(question.id);
        }}>{t('buttons.delete')}</button>
    )
}

export default DeleteQuestion;