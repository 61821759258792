import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function RenderDefaultQuestion( {question, startEditing} ){
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    return (
        <div>
        <h4>{t('questionCard.id')} {question.id}</h4>
    <h4>Question (ENG): {question.questionLabel}</h4>
    <h4>Question (FR): {question.questionLabelFR}</h4>
    <h4>{t('questionCard.type')} {question.questionType}</h4>
    {question.questionType === "pickPictures" && question.imageChoices && (
        <div className="question-pictures-details">
            {question.imageChoices.map((imageChoices, index) => (
                <img key={index} src={imageChoices.imageLink} alt="choices.value" />
            ))}
        </div>
    )}
    {question.questionType === "yes/no" && question.choices && question.choicesFR &&(
        <div className="container-choices">
            <div className="show-array">
                <h4>{t('questionCard.answers')} (ENG): </h4>
                <ul>
                    {question.choices.map((choices, index) => (
                        <li key={index}><h6>{choices}</h6></li>
                    ))}
                </ul>
            </div>
            <div className="show-array">
                <h4>{t('questionCard.answers')} (FR): </h4>
                <ul>
                    {question.choicesFR.map((choicesFR, index) => (
                        <li key={index}><h6>{choicesFR}</h6></li>
                    ))}
                </ul>
            </div>
        </div>
    )}
    <h4>{t('questionCard.visibility')} {question.visibleIf}</h4>
            <div className="buttons-edit">
                <button className="button btn-edit" onClick={() => startEditing(question)}>{t('buttons.edit')}</button>
            </div>
        </div>
    )
}

export default RenderDefaultQuestion;