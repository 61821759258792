import { Link, useNavigate, useLocation  } from "react-router-dom";
import classes from "./Navigation.module.css"
import Logout from "../Logout";
import React, {useContext, useEffect} from "react";
import logoHeader from '../../assets/IconCircle.png';
import ToggleLanguage from "./ToggleLanguage";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { LanguageContext } from "../App/LanguageContext";


function Navigation({setIsAuthenticated}) {
  const location = useLocation();
  const navigate = useNavigate();
  const isNotMain = location.pathname !== '/management/main';
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const handleBack = async() => {
        navigate(-1);
    };
  return (
    <header className={classes.header}>
      <div className="header-left">
      <img className="img-header-logo" src={logoHeader} alt="logo_header"/>
          { isNotMain && (<button className="btn-back" onClick={handleBack}>↩︎</button>)}
      </div>
      <div className="header-middle">
      <nav>
        <ul>
          <li className="header-link">
            <Link to="/management/main">{t('navigation.main')}</Link>
          </li>
          <li className="header-link">
            <Link to="/management/clients">{t('navigation.clients')}</Link>
          </li>
          <li className="header-link">
            <Link to="/management/quotes">{t('navigation.quotes')}</Link>
          </li>
          <li className="header-link">
            <Link to="/management/surveys">{t('navigation.surveys')}</Link>
          </li>
        </ul>
      </nav>
      </div>
      <div className="header-right">
        <ToggleLanguage />
        <Logout setIsAuthenticated={setIsAuthenticated} /></div>
    </header>
  );
}

export default Navigation;