import React from 'react';
import './../../index.css';

function YesNoChoiceComponent({ editedChoices, handleChoiceEdit, addChoice, deleteChoice, editedChoicesFR }){
    return (
        <div className="add-choices-arrays">
            <div className="choices">
                <h5>Answers (ENG): </h5>
                <button className="btn-add-icon" onClick={() => addChoice("ENG")}>➕</button>
                <ul>
                    {editedChoices.map((choice, index) => (
                        <li key={index}>
                            <input className="input-choice" value={choice} onChange={(e) => handleChoiceEdit(e, index, "ENG")} />
                            <button className="btn-delete-icon" onClick={() => deleteChoice(index, "ENG")}>➖</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="choices">
                <h5>Answers (FR): </h5>
                <button className="btn-add-icon" onClick={() => addChoice("FR")}>➕</button>
                <ul>
                    {editedChoicesFR.map((choice, index) => (
                        <li key={index}>
                            <input className="input-choice" value={choice} onChange={(e) => handleChoiceEdit(e, index, "FR")} />
                            <button className="btn-delete-icon" onClick={() => deleteChoice(index, "FR")}>➖</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
export default YesNoChoiceComponent;