import React, {useContext, useEffect} from 'react';
import {LanguageContext} from "../App/LanguageContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import './../../index.css';

function EditImagesComponent({ setUploading, setEditedImageChoices,
                                 editedImageChoices, fileInputRef, uploadProgress,
                                 uploadedImageLinks, editedType, question}){

    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(() => {
        const initializedImages = question.imageChoices.map(image => ({...image, alreadyUploaded: true}));
        setEditedImageChoices(initializedImages);
    }, [question, setEditedImageChoices]);

    const handleDeleteImage = (index) => {
        setEditedImageChoices(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const newImage = {
                 imageLink: URL.createObjectURL(file),
                name: file.name,
                raw: file
            };
            setEditedImageChoices(prevImages => [...prevImages, newImage]);
        }
    };


    return(
        <div>
            {(editedType === "pickPictures" || question.questionType === "pickPictures") && (
                <>
                    <div className="image-upload-preview-container">
                        {editedImageChoices.length < 6 && (
                            <>
                                <label className="select-img" htmlFor="imageInput">{t('buttons.selectImg')}</label>
                                <input
                                    id="imageInput"
                                    accept="image/*"
                                    type="file"
                                    onChange={handleImageChange}
                                    ref={fileInputRef}
                                    hidden={uploadProgress > -1 || uploadedImageLinks}
                                />
                                {!uploadedImageLinks && uploadProgress > -1 && (
                                    <div>
                                        <label htmlFor="file">Upload Progress:</label>
                                        <progress id="file" value={uploadProgress} max="100">
                                            {uploadProgress}%
                                        </progress>
                                        <span>{uploadProgress}%</span>
                                    </div>
                                )}
                            </>
                        )}
                        {editedImageChoices.length > 0 && (
                            <div className="image-preview">
                                {editedImageChoices.length === 6 && (
                                    <div><h4 className="label-maximum-images">{t('questionCard.maxImg')}</h4></div>
                                )}
                                <div className="image-preview-container">
                                    {editedImageChoices.map((image, index) => (
                                        <div key={index} className="image-preview-item">
                                            <img src={image.imageLink} alt={`Preview ${index}`} className="image" />
                                            <button className="btn-delete-on-image" onClick={() => handleDeleteImage(index)}>X</button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                </>
            )}
        </div>
    )

}

export default EditImagesComponent;