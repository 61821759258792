import React from 'react';
import './ModuleCSS/Language.css';
import logoFr from '../assets/CLAPE_FR_MainLogo_NOIR-1024x548.png';
import logoEn from '../assets/CLAPE_ENG_MainLogo_BLACK-1024x541.png';


function Language(props) {
    const handleLanguageChange = (lang) => {
        props.onNext(lang);
    }

    return (
        <div className="container">
            <div className="block-fr" role="button" onClick={() => handleLanguageChange('fr')}>
                <h2>FRANÇAIS</h2>
                <img className="img-lang" src={logoFr} alt="select french"/>
            </div>
            <div className="block-en" role="button" onClick={() => handleLanguageChange('en')}>
                <h2>ENGLISH</h2>
                <img className="img-lang" src={logoEn} alt="select english" />
            </div>
        </div>
    );
}
export default Language;