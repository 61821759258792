import React, { useState, useEffect } from "react";
import { Survey } from "survey-react";
import {Model} from "survey-core";
import "survey-core/survey.i18n";
import './ModuleCSS/Question.css';
import 'animate.css';
import { getFunctions, httpsCallable } from 'firebase/functions';


function SurveyToClient({ onComplete , lang, surveyId}) {
    const [surveyJSON, setSurveyJSON] = useState(null);
    const [survey, setSurvey] = useState(null);
    const functions = getFunctions();


    useEffect(() => {
        if (surveyJSON) {
            const newSurvey = new Model(surveyJSON);
            newSurvey.onValueChanged.add((sender, options) => {
                sender.runConditions();
            });
            setSurvey(newSurvey);
        }
    }, [surveyJSON]);

    useEffect(() => {
        if (surveyId && lang) {
            const fetchSurveyQuestions = httpsCallable(functions, 'fetchSurveyQuestions');
            fetchSurveyQuestions({surveyId, lang})
                .then((result) => {
                    setSurveyJSON(result.data.surveyJSON);
                })
                .catch((error) => {
                    console.error('Error fetching survey questions:', error);
                });
        }
    }, [lang, surveyId, functions]);


    const onSurveyComplete = (survey) => {
        const responses = survey.data;
        onComplete(responses);
    };

    return survey ?
        <div>
            <Survey
                model={survey}
                onComplete={onSurveyComplete}
            />
            <div><a href="https://fr.clape.ca/" target="_blank" rel="noopener noreferrer"
                    className="copyright">© 2023 Copyright  Clape Agency Inc.</a></div>
        </div>
        : <p>Loading...</p>;
}

export default SurveyToClient;
